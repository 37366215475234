<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['เซียน', 'จัดการ']" />
    <div>
      <button class="btn btn-success mb-2" @click="openFormCreate" v-show="canEdit">
        เพิ่มเซียน
      </button>
      <loading v-show="isLoading" />

      <div class="table-responsive" v-show="!isLoading">
        <CDataTable :items="items" :fields="fields" :items-per-page-select="false" :items-per-page="10" :no-items-view="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }" :table-filter="true" border striped pagination dark hover size="sm"
          add-table-classes="w-100 text-wrap text-center">
          <template #userId="{ item }">
            <td data-toggle="tooltip" :title="item.imageURL">
              <router-link :to="'/members/' + item.userId" target="_blank">
                {{ item.userId }}
              </router-link>
            </td>
          </template>
          <template #linkRegister="{ item }">
            <td data-toggle="tooltip" :title="item.imageURL">
              <a :href="webURL + '/login?ref=' + item.encryptedUserId" target="_blank">
                <span>{{ webURL + "/login?ref=" + item.encryptedUserId }}</span>
              </a>
            </td>
          </template>
          <template #linkReport="{ item }">
            <td data-toggle="tooltip" :title="item.imageURL">
              <router-link :to="{
                name: 'ResultSian',
                params: { userId: item.encryptedUserId },
              }" target="_blank">
                {{ baseURL }}/result-sian/{{ item.encryptedUserId }}
              </router-link>
            </td>
          </template>
          <template #edit="{ item }">
            <td class="d-flex">
              <button class="btn btn-primary" @click="openFormEdit(item)" :disabled="!canEdit">
                <i class="fa fa-edit"></i>
              </button>
              <button class="btn btn-danger ml-1" @click="deleteSian(item)" :disabled="!canEdit">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </template>
        </CDataTable>
        <!-- <table class="w-100 table table-dark table-bordered table-striped">
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>ชื่อ</th>
                <th>ค่าคอมมิชชั่น (%)</th>
                <th>ค่าส่งบริษัท (%)</th>
                <th>ลิ้งสมัคร</th>
                <th>ลิ้งดูรายงาน</th>
                <th>ประเภทคอมมมิชชั่น</th>
                <th>แก้ไข/ลบ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>
                  {{ getGameCategoryName(item.category) }}
                </td>
                <td>
                  <router-link to="/ResultSian">ลิ้งสมัคร</router-link>
                </td>
                <td>
                  <router-link to="/ResultSian">ลิ้งดูรายงาน</router-link>
                </td>
                <td>{{ item.showOrder }}</td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="openFormEdit(item)"
                    :disabled="!canEdit"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table> -->
      </div>
    </div>
    <b-modal id="modal-form" body-bg-variant="dark" body-text-variant="light" header-bg-variant="dark"
      header-text-variant="light" footer-bg-variant="dark" footer-text-variant="light"
      :title="(form.id == null ? 'เพิ่ม' : 'แก้ไข') + 'เซียน'" cancel-title="ยกเลิก" ok-title="ตกลง" hide-footer>
      <ValidationObserver ref="observer" tag="form" @submit.prevent="submitForm" novalidate>
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <label for="form-game-product-name" class="col-12">
                #สมาชิก (ID)
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input id="form-sian-name" type="number" class="form-control mr-1" v-model.number="form.userId"
                    autocomplete="off" :readonly="form.id" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-commission-huay" class="col-12">
                ค่าคอมมิชชั่นหวย (%)
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input id="form-commission-huay" type="number" min="0" max="100" class="form-control mr-1"
                    v-model="form.commissionHuay" autocomplete="off" @keypress="$root.onlyNumber" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-commission-game" class="col-12">
                ค่าคอมมิชชั่นเกม (%)
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input id="form-commission-game" type="number" min="0" max="100" class="form-control mr-1"
                    v-model="form.commissionGame" @keypress="$root.onlyNumber" autocomplete="off" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-autoPayoutHuay">จ่ายอัตโนมัติ(หวย)</label>
              <div class="col-12">
                <ValidationProvider rules="" v-slot="v">
                  <select v-model="form.autoPayoutHuay" class="form-control" id="form-autoPayoutHuay">
                    <option v-for="item in yesNoOptions" :key="item.value" :value="item.value">
                      {{ item.text }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-autoPayoutGame">จ่ายอัตโนมัติ(เกม)</label>
              <div class="col-12">
                <ValidationProvider rules="" v-slot="v">
                  <select v-model="form.autoPayoutGame" class="form-control" id="form-autoPayoutGame">
                    <option v-for="item in yesNoOptions" :key="item.value" :value="item.value">
                      {{ item.text }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-payType">ประเภทการจ่าย</label>
              <div class="col-12">
                <ValidationProvider rules="" v-slot="v">
                  <select v-model="form.payType" class="form-control" id="form-affiliate">
                    <option v-for="typePay in payTypeList" :key="typePay.value" :value="typePay.value">
                      {{ typePay.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-affiliate">ประเภทคอมมมิชชั่น</label>
              <div class="col-12">
                <ValidationProvider rules="" v-slot="v">
                  <select v-model="form.commissionType" class="form-control" id="form-affiliate">
                    <option v-for="typeCommission in commissionTypeList" :key="typeCommission.value"
                      :value="typeCommission.value">
                      {{ typeCommission.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <hr />
            <div class="form-group row">
              <div class="col-12 text-right">
                <button class="btn btn-danger" @click="closeForm" type="button">
                  ยกเลิก
                </button>
                <button class="btn btn-primary ml-3" type="submit">
                  {{ form.id ? "บันทึก" : "เพิ่ม" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style></style>

<script>
import SianProvider from "@/resources/sian_provider"
const sianService = new SianProvider()
export default {
  data() {
    return {
      form: {
        id: null,
        userId: "",
        commissionHuay: "",
        commissionGame: "",
        payType: "",
        autoPayoutHuay: true,
        autoPayoutGame: true,
        commissionType: "",
      },
      payTypeList: [
        { value: "daily", name: "รายวัน" },
        { value: "weekly", name: "รายสัปดาห์" },
        { value: "monthly", name: "รายเดือน" },
      ],
      commissionTypeList: [{ value: "lost_percent", name: "ยอดเสีย" }],
      webURL: "",
      baseURL: window.location.origin,
      items: [],
      gameProductCategories: [],
      isLoading: false,
      yesNoOptions: [
        {
          text: "ใช่",
          value: true,
        },
        {
          text: "ไม่",
          value: false,
        },
      ],
      fields: [
        {
          key: "row_number",
          label: "ลำดับ",
        },
        {
          key: "userId",
          label: "#สมาชิก",
        },
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "name",
          label: "ชื่อ",
        },
        {
          key: "commissionHuay",
          label: "ค่าคอมมิชชั่นหวย (%)",
        },
        {
          key: "commissionGame",
          label: "ค่าคอมมิชชั่นเกม (%)",
        },
        {
          key: "autoPayoutHuay",
          label: "จ่ายอัตโนมัติ(หวย)",
        },
        {
          key: "autoPayoutGame",
          label: "จ่ายอัตโนมัติ(เกม)",
        },
        {
          key: "linkRegister",
          label: "ลิ้งสมัคร",
        },
        {
          key: "linkReport",
          label: "ลิ้งดูรายงาน",
        },
        {
          key: "commissionType",
          label: "ประเภทคอมมิชชั่น",
        },
        {
          key: "payType",
          label: "ประเภทการจ่าย",
        },
        {
          key: "edit",
          label: "แก้ไข/ลบ",
        },
      ],
    }
  },
  computed: {
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "member_sian_edit"
      )
    },
    gameProductCategoryMapCode() {
      let map = {}
      this.gameProductCategories.forEach((ele) => {
        map[ele.code] = ele
      })
      return map
    },
  },
  mounted() {
    this.clearForm()
    this.getAllSian()
  },
  methods: {
    clearForm() {
      this.form.id = null
      this.form.userId = null
      this.form.commissionHuay = ""
      this.form.commissionGame = ""
      this.form.autoPayoutHuay = true
      this.form.autoPayoutGame = true
      this.form.commissionType = "lost_percent"
      this.form.payType = "daily"
    },
    openFormCreate() {
      this.clearForm()
      this.$bvModal.show("modal-form")
    },
    openFormEdit(item) {
      this.clearForm()
      this.form.id = item.userId
      this.form.userId = item.userId
      this.form.commissionHuay = item.commissionHuay
      this.form.commissionGame = item.commissionGame
      this.form.commissionType = item.commissionType
      this.form.autoPayoutHuay = item.autoPayoutHuay == "ใช่" ? true : false
      this.form.autoPayoutGame = item.autoPayoutGame == "ใช่" ? true : false
      const p = [...this.payTypeList]
      const c = [...this.commissionTypeList]
      this.form.payType = p.find((i) => i.name === item.payType).value
      this.form.commissionType = c.find(
        (i) => i.name === item.commissionType
      ).value
      this.$bvModal.show("modal-form")
    },
    async deleteSian(item) {
      let confirm = await this.swal.confirm(
        "Delete",
        "ท่านต้องการลบเซียนใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.swal.processing()
      const res = await sianService.deleteSian(item.userId)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess("ลบเซียนสำเร็จ", () => {
          this.getAllSian()
        })
      }
    },
    closeForm() {
      this.$bvModal.hide("modal-form")
    },
    async submitForm() {
      console.log(this.form)
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }
      this.swal.processing()
      const res = !this.form.id
        ? await sianService.addSian(this.form)
        : await sianService.editSian(this.form.userId, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.form.id ? "เพิ่มเซียนสำเร็จ" : "แก้ไขเซียนสำเร็จ",
          () => {
            this.getAllSian()
            this.closeForm()
          }
        )
      }
    },
    async getAllSian() {
      this.isLoading = true
      const res = await sianService.getAllSian()
      console.log(res)
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.items = res.result.list
      this.webURL = res.result.webURL
      const c = [...this.commissionTypeList]
      const p = [...this.payTypeList]
      this.items.forEach(function (item, i) {
        item.row_number = i + 1
        item.autoPayoutHuay = item.autoPayoutHuay ? "ใช่" : "ไม่"
        item.autoPayoutGame = item.autoPayoutGame ? "ใช่" : "ไม่"
        item.commissionType = c.find(
          (i) => i.value === item.commissionType
        ).name
        item.payType = p.find((i) => i.value === item.payType).name
      })
    },
  },
}
</script>
